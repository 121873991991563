import { NgModule } from '@angular/core';
import {
  InMemoryScrollingOptions,
  RouterModule,
  Routes,
  provideRouter,
  withInMemoryScrolling,
} from '@angular/router';
import { AddCardComponent } from './auth/signup/str-user/components/add-card/add-card.component';
import { PaidUserGuard } from './shared/guards/paid-user.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'site',
    data: { title: 'Parent ProTech | Sign Up', pageTitle: 'Sign Up' },
  },
  {
    path: 'add-card',
    component: AddCardComponent,
    data: { title: 'Parent ProTech | Add Card', pageTitle: 'Add Card' },
  },
  // OKAG Redirect Route for Now
  {
    path: 'okag',
    redirectTo: 'billing/6/oklahomaassembliesofgod/en/signup'
    // loadChildren: () =>
    //   import('./okag/okag.module').then((mod) => mod.OkagModule),
  },
  // Auth Routes
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/login/login.module').then((mod) => mod.LoginModule),
  },
  {
    path: 'verify-code',
    loadChildren: () =>
      import('./auth/tfa-code/tfa-code.module').then(
        (mod) => mod.TfaCodeModule
      ),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./auth/signup/str-user/str-user.module').then(
        (mod) => mod.StrUserModule
      ),
  },
  {
    path: ':organization_id/:organization_name/:language/signup',
    loadChildren: () =>
      import('./auth/signup/org-user/org-user.module').then(
        (mod) => mod.OrgUserModule
      ),
  },
  // /billing/2/codefiorganization/en/signup new billing-org signup urls
  {
    path: 'bo/:bo_id/:bo_name/:language/signup',
    loadChildren: () =>
      import('./auth/signup/org-user/org-user.module').then(
        (mod) => mod.OrgUserModule
      ),
  },
  // /18/52/2023-2024/ideapublicschools/en/signup old signup urls with school year
  {
    path: ':organization_id/:sub_org_id/:organization_name/:sub_org_name/:language/signup',
    loadChildren: () =>
      import('./auth/signup/org-user/org-user.module').then(
        (mod) => mod.OrgUserModule
      ),
  },
  {
    path: ':organization_id/:sub_org_id/:organization_name/:sub_org_name/:language/signup',
    loadChildren: () =>
      import('./auth/signup/org-user/org-user.module').then(
        (mod) => mod.OrgUserModule
      ),
  },
  {
    path: ':organization_id/:school_year_id/:school_year/:organization_name/:language/signup',
    loadChildren: () =>
      import('./auth/signup/org-user/org-user.module').then(
        (mod) => mod.OrgUserModule
      ),
  },
  {
    path: ':organization_id/:sub_org_id/:school_year_id/:school_year/:organization_name/:sub_org_name/:language/signup',
    loadChildren: () =>
      import('./auth/signup/org-user/org-user.module').then(
        (mod) => mod.OrgUserModule
      ),
  },
  {
    path: 'forgot-username',
    loadChildren: () =>
      import('./auth/forgot-username/forgot-username.module').then(
        (mod) => mod.ForgotUsernameModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./auth/forgot-password/forgot-password.module').then(
        (mod) => mod.ForgotPasswordModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./auth/reset-password/reset-password.module').then(
        (mod) => mod.ResetPasswordModule
      ),
  },
  {
    path: 'verify-email',
    loadChildren: () =>
      import('./auth/verify-email/verify-email.module').then(
        (mod) => mod.VerifyEmailModule
      ),
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('./billing/billing.module').then((mod) => mod.BillingModule),
  },
  // End of Auth Routes
  // Single Level Routes
  {
    path: 'contact',
    loadChildren: () =>
      import('./contact/contact.module').then((mod) => mod.ContactModule),
  },
  {
    path: 'frequently-asked-questions',
    loadChildren: () => import('./faq/faq.module').then((mod) => mod.FaqModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then(
        (mod) => mod.PrivacyPolicyModule
      ),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./terms-conditions/terms-conditions.module').then(
        (mod) => mod.TermsConditionsModule
      ),
  },
  {
    path: 'main',
    redirectTo: 'site',
  },
  // End of Single Level Routes
  // Courses Routes
  {
    path: 'courses',
    canActivate: [PaidUserGuard],
    loadChildren: () =>
      import('./course-library/course-library.module').then(
        (mod) => mod.CourseLibraryModule
      ),
  },
  // End of Courses Routes
  // Admin Routes
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((mod) => mod.AdminModule),
  },
  // End of Admin Routes
  // Profile Routes
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((mod) => mod.ProfileModule),
  },
  // End of Profile Routes
  // New Marketing Site Routes
  {
    path: 'site',
    loadChildren: () =>
      import('./site/site.module').then((mod) => mod.SiteModule),
  },

  // End Marketing Site Routes
];

const scrollOpts: InMemoryScrollingOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [provideRouter(routes, withInMemoryScrolling(scrollOpts))],
})
export class AppRoutingModule { }
