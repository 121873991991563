import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { BaseModel } from '../models/base-model';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  subsApiUrl = `${environment.apiUrl}/subscriptions`;
  customersApiUrl = `${environment.apiUrl}/customers`;
  constructor(private http: HttpClient) {}

  // Subscription API calls
  createIncompleteSubscription(params) {
    return this.http
      .post(`${this.subsApiUrl}/create_incomplete_subscription`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  createStripeCustomer(params) {
    return this.http.post(`${this.subsApiUrl}/create_customer`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  saveSubscriptionIdOnUser(params) {
    return this.http
      .post(`${this.subsApiUrl}/save_subscription_id_on_user`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  retrieveSubscription(params) {
    return this.http
      .post(`${this.subsApiUrl}/retrieve_subscription`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  cancelSubscription(params) {
    return this.http
      .post(`${this.subsApiUrl}/cancel_subscription`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }
  // End of Subscription API calls

  // Invoice API calls
  retrieveInvoices(params) {
    return this.http
      .post(`${this.subsApiUrl}/get_customer_invoices`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }
  // End of Invoice API calls

  // Payment Method API calls
  retrievePaymentMethods(params) {
    return this.http
      .post(`${this.customersApiUrl}/retrieve_payment_methods`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  // takes email & payment_method_id
  addPaymentMethod(params) {
    return this.http
      .post(`${this.customersApiUrl}/add_payment_method`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  // takes email & payment_method_id
  setPaymentMethodAsDefault(params) {
    return this.http
      .post(`${this.customersApiUrl}/set_default_payment_method`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  // takes email & payment_method_id
  removePaymentMethod(params) {
    return this.http
      .post(`${this.customersApiUrl}/remove_payment_method`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {})
      );
  }
  // End of Payment Method API calls

  private handleBaseResponse(res: BaseModel) {
    if (res.success) {
      return res.payload;
    } else {
      throwError(res.errors);
      return res;
    }
  }

  private handleError(error) {
    let errorMessage = '';
    // server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error['error']['errors']}`;
    return throwError({ status: error.status, msg: errorMessage });
  }
}
