<form>
  <div #addressInfo>
  </div>
  <div #paymentInfo class="mt-2"></div>
  <div class="d-grid gap-2">
    <button *ngIf="btnDisabled" class="btn btn-primary btn-block mt-5 disabled" disabled="true">{{
      'payment.submitButton' | translate }}</button>
    <button *ngIf="!btnDisabled" class="btn btn-primary btn-block mt-5" (click)="submitElements($event)"> {{
      'payment.submitButton' | translate }}
      <fa-icon *ngIf="submitting" [icon]="['fas', 'spinner']" size="lg" spin="true" class="ms-2 text-white">
      </fa-icon>
    </button>
  </div>
</form>
<div class="w-100 text-center" *ngIf="cardError">
  <p class="text-danger">{{ 'payment.cardError' | translate }}</p>
</div>
