import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

interface FilterOptions {
  content: string | null;
  selectedCat: string | null;
  selectedCatId: number | null;
  viewing: string | null;
  viewChange: boolean;
  seriesId: number | null;
  language?: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class FilterOptionsService {
  private storageKey = 'filterOptions';
  private filterOptionsSubject: BehaviorSubject<FilterOptions>;
  public currentFilter: Observable<FilterOptions>;
  constructor(private lsService: LocalStorageService) {
    this.filterOptionsSubject = new BehaviorSubject<FilterOptions>(
      this.lsService.getItem(this.storageKey)
    );
    this.currentFilter = this.filterOptionsSubject.asObservable();
  }

  public get currentFilterValue(): FilterOptions {
    return this.filterOptionsSubject.value;
  }

  save(filterOptions: FilterOptions) {
    this.lsService.setItem(this.storageKey, filterOptions);
    this.filterOptionsSubject.next(filterOptions);
  }

  remove() {
    this.lsService.setItem(this.storageKey, null);
    this.lsService.removeItem(this.storageKey);
    this.filterOptionsSubject.next(null);
  }
}
