export const environment = {
  production: true,
  staging: false,
  webUrl: 'https://www.parentprotech.com',
  signupSuccessUrl: 'https://www.parentprotech.com/courses?signup=success',
  // apiUrl: 'https://parent-protech-staging.codefilabsapi.com/api/v1',
  apiUrl: 'https://api.parentprotech.com/api/v1',
  stripePkey:
    'pk_live_51KoFKkLXiFrPPt9dwpZ79dsw8EHocxiqW1o73U2C5Q3orSuC8tBW1i5lBKZ0mqouclGhlQkihQUQwoiKZ9hZCrgx004utGeiBS',
  stripePriceKey: 'price_1NpBgqLXiFrPPt9dTEt6zIEW',
  stripeTestPriceKey: 'price_1NlY8yLXiFrPPt9dPL5VmGms',
  googleClientId: '925836349364-91njl3n4h2d48dptto5i4542o4r5pt35.apps.googleusercontent.com',
  msClientId: '499161c8-22dc-4f63-ae14-fa4a6e5dcdc0',
  username: 'sso_admin',
  password: 'Ppt123!@#'
};
