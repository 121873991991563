
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  cd: Date = new Date();

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const nd = this.returnNd()
    const httpRequest = req.clone({
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': nd.toUTCString()
      })
    })
    return next.handle(httpRequest)
  }

  returnNd() {
    const newDate = this.cd.setHours(this.cd.getHours() + 4)
    const otherDate = new Date(newDate)
    return otherDate
  }
}
