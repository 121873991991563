import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalsComponent } from './modals.component';
import { FaIconModule } from '../shared/modules/fa-icon/fa-icon.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ModalsComponent],
  imports: [CommonModule, FaIconModule, TranslateModule],
  exports: [ModalsComponent],
})
export class ModalsModule {}
