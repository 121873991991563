import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { FaIconModule } from '../shared/modules/fa-icon/fa-icon.module';
import { ContactBlockComponent } from './contact-block/contact-block.component';
import {
  TranslateModule,
  TranslatePipe,
  TranslateService,
} from '@ngx-translate/core';
import { IdeaModalComponent } from './idea-modal/idea-modal.component';
import { ModalsModule } from '../modals/modals.module';
import { NoSuborgModalComponent } from './no-suborg-modal/no-suborg-modal.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { SharedModule } from '../shared/shared.module';
@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    ContactBlockComponent,
    IdeaModalComponent,
    NoSuborgModalComponent,
    TutorialComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FaIconModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ModalsModule,
    SharedModule,
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    ContactBlockComponent,
    IdeaModalComponent,
    NoSuborgModalComponent,
    TutorialComponent,
  ],
  providers: [TranslatePipe, TranslateService],
})
export class ComponentsModule {}
