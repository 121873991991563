import {
  AfterViewInit,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './shared/services/local-storage.service';
import { UserService } from './shared/services/user.service';
import { LanguageService } from './shared/services/language.service';
import { Title, Meta } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, interval } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Renderer2, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router, RouterConfigOptions } from '@angular/router';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'Parent ProTech';
  updated = false;
  private subs = new Subscription();
  // @ViewChild('bugHerd', { static: false }) bugHerdScript: any;
  constructor(
    private tsService: Title,
    private metaService: Meta,
    private storage: LocalStorageService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    public updates: SwUpdate,
    private renderer: Renderer2,
    private router: Router,
    @Inject(DOCUMENT) private _document: any,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // if (updates.isEnabled) {
    //   this.ngZone.runOutsideAngular(() => {
    //     interval(60 * 60 * 60).subscribe(() =>
    //       updates.versionUpdates.subscribe(() => {
    //         Swal.fire({
    //           icon: 'warning',
    //           title: 'Update Available',
    //           text: 'Parent ProTech is frequently updating our platform to provide you with the latest tech updates. Click "Ok" to see the latest version of parentprotech.com',
    //           showConfirmButton: true,
    //           showCancelButton: true,
    //           confirmButtonColor: '#3872dd'
    //         }).then((result) => {
    //           if (result.isConfirmed) {
    //             this.updated = true
    //             window.location.reload();
    //           }
    //           if (result.isDismissed) {
    //             this.updated = false
    //           }
    //         })
    //       })
    //     )
    //   })
    // }
    let userLanguage = this.storage.getItem('currentLanguage');
    if (!userLanguage) {
      userLanguage = 'en';
      this.storage.setItem('currentLanguage', 'en');
    } else {
      if (userLanguage === 'english') {
        userLanguage = 'en';
        this.translateService.setDefaultLang('en');
      } else if (userLanguage === 'spanish') {
        userLanguage = 'es';
        this.translateService.setDefaultLang('es');
      }
    }
    this.translateService.addLangs(['en', 'es']);
    this.translateService.setDefaultLang(userLanguage);
    this.translateService.use(userLanguage);
    this.tsService.setTitle('Parent ProTech');
    if (
      isPlatformBrowser(this.platformId) &&
      environment.webUrl === 'https://www.parentprotech.com'
    ) {
      //Client side execution
      this.injectScripts();
    }
  }

  ngOnInit() {
    this.subToRouterEvents();
    this.setMetaTags();
    this.setupBugHerd();
  }

  injectScripts() {
    // first
    // const gtmScriptTag = this.renderer.createElement('script');
    // gtmScriptTag.type = 'text/javascript';
    // gtmScriptTag.src =
    //   'https://www.googletagmanager.com/gtag/js?id=G-VG1Q18T624';
    // this.renderer.appendChild(this._document.body, gtmScriptTag);
    // // second
    // const gtagInitScript = this.renderer.createElement('script');
    // gtagInitScript.type = 'text/javascript';
    // gtagInitScript.text = `
    // window.dataLayer = window.dataLayer || [];
    // function gtag(){dataLayer.push(arguments);}
    // gtag('js', new Date());
    // gtag('config', '	G-VG1Q18T624');
    // `;
    // this.renderer.appendChild(this._document.body, gtagInitScript);
    // const gaScript = this.renderer.createElement('script');
    // gaScript.type = 'text/javascript';
  }

  subToRouterEvents() {
    this.subs.add(
      this.router.events.subscribe((y: NavigationEnd) => {
        if (y instanceof NavigationEnd) {
          if (isPlatformBrowser(this.platformId)) {
            (<any>window).gtag('config', 'G-VG1Q18T624', { page_path: y.url });
          }
          const url = environment.webUrl + y.url;
          const tags = [{ property: 'og:url', content: url }];
          tags.forEach((x) => {
            this.metaService.updateTag(x);
          });
        }
      })
    );
  }

  ngAfterViewInit() {
    this.setupBugHerd();
  }

  setupBugHerd() {
    // if (isPlatformBrowser(this.platformId) && environment.staging) {
    //   const bugHerd = this.renderer.createElement('script');
    //   bugHerd.type = 'text/javascript';
    //   bugHerd.src =
    //     'https://www.bugherd.com/sidebarv2.js?apikey=gyhned0lmmxxinvajaliqg';
    //   this.renderer.appendChild(this.bugHerdScript.nativeElement, bugHerd);
    // }
    // comment for commit
  }

  setMetaTags() {
    const tags = [
      {
        name: 'description',
        content:
          'Parent ProTech equips students, families, and educators to safely navigate technology.',
      },
    ];
    tags.forEach((x) => {
      this.metaService.updateTag(x);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
