import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PaidUserGuard {
  constructor(
    private router: Router,
    private userService: UserService,
    private localStorage: LocalStorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentUser = this.userService.currentUserValue;

    if (currentUser === null) {
      this.localStorage.setItem('redirectUrl', state.url);
      this.router.navigate(['/login'], {
        queryParams: { incomplete: true },
      });
      return false;
    } else if (currentUser && currentUser.inactive) {
      this.localStorage.setItem('redirectUrl', state.url);
      this.router.navigate(['signup/payment'], {
        queryParams: { incomplete: true },
      });
      return false;
    } else if (
      (currentUser && currentUser.organization_id) ||
      (currentUser && currentUser.customer_id && currentUser.subscription_id)
    ) {
      return true;
    } else if (currentUser && currentUser.billing_organization_id) {
      return true;
    } else {
      this.localStorage.setItem('redirectUrl', state.url);
      this.router.navigate(['login']);
      return false;
    }
  }
}
