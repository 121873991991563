export class User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  profile_img: string;
  job_title: string;
  show_job_title: string;
  language: string;
  country: string;
  timezone: string;
  children: Array<{ grade: number }>;
  role: string;
  removed: boolean;
  customer_id: string;
  user_type: string;
  subscription_id: string;
  organization_id: number;
  sub_organization_id: number;
  school: string;
  pop_up_ack: boolean;
  demo_ack: boolean;
  has_sub_orgs: boolean;
  newsletter: boolean;
  b2c_incomplete: boolean;
  b2c: boolean;
  inactive_org: boolean;
  tfa_code: string;
  tutorial_ack: boolean;
  educator: boolean;
  parent_billing_organization_id: number;
  billing_organization_id: number;
  billing_admin: boolean;
  inactive: boolean;
  constructor({
    id = 0,
    first_name = '',
    last_name = '',
    email = '',
    phone = '',
    job_title = '',
    show_job_title = '',
    language = '',
    country = '',
    timezone = '',
    children = [],
    removed = false,
    customer_id = '',
    user_type = '',
    subscription_id = '',
    organization_id = null,
    sub_organization_id = null,
    school = '',
    pop_up_ack = false,
    demo_ack = false,
    has_sub_orgs = false,
    newsletter = false,
    b2c_incomplete = false,
    b2c = false,
    inactive_org = false,
    tfa_code = null,
    tutorial_ack = false,
    educator = false,
    parent_billing_organization_id = null,
    billing_organization_id = null,
    billing_admin = false,
    inactive = false,
    ...rest
  }) {
    Object.assign(this, rest);
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.phone = phone;
    this.job_title = job_title;
    this.show_job_title = show_job_title;
    this.language = language;
    this.country = country;
    this.timezone = timezone;
    this.children = children;
    this.user_type = user_type;
    this.role = this.mapForUserRole(rest.role);
    this.removed = removed;
    this.customer_id = customer_id;
    this.user_type = user_type;
    this.subscription_id = subscription_id;
    this.organization_id = organization_id;
    this.sub_organization_id = sub_organization_id;
    this.school = school;
    this.pop_up_ack = pop_up_ack;
    this.demo_ack = demo_ack;
    this.has_sub_orgs = has_sub_orgs;
    this.newsletter = newsletter;
    this.b2c_incomplete = b2c_incomplete;
    this.b2c = b2c;
    this.inactive_org = inactive_org;
    this.tfa_code = tfa_code;
    this.tutorial_ack = tutorial_ack;
    this.educator = educator;
    this.parent_billing_organization_id = parent_billing_organization_id;
    this.billing_organization_id = billing_organization_id;
    this.billing_admin = billing_admin;
    this.inactive = inactive;
  }

  mapForUserRole(id: number) {
    switch (id) {
      case 1: {
        return 'user';
      }
      case 2: {
        return 'admin';
      }
      case 3: {
        return 'super_admin';
      }
      default: {
        return 'user';
      }
    }
  }
}
