import {
  Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, Input, Inject, EventEmitter, Output, PLATFORM_ID
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User } from '../../../../../shared/models/user';
import { UserService } from '../../../../../shared/services/user.service';
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { StripeService } from '../../../../../shared/services/stripe.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit, OnDestroy {
  @ViewChild('stripeScript', { static: false }) stripeScript: ElementRef
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef
  @ViewChild('addressInfo', { static: false }) addressInfo: ElementRef
  @ViewChild('paymentInfo', { static: false }) paymentInfo: ElementRef
  @ViewChild('cardNumber', { static: false }) cardNumber: ElementRef
  @ViewChild('cardExpiry', { static: false }) cardExpiry: ElementRef
  @ViewChild('cardCvc', { static: false }) cardCvc: ElementRef
  form: FormGroup
  formValues
  hasError: boolean
  cardHandler = this.onCardChange.bind(this);
  addyHandler = this.onAddyChange.bind(this);
  error: string;
  errorMsg: string
  successMsg: string
  subs = new Subscription()
  modalId = 'billingModal'
  token: any
  invalidError: string
  // states = stateList
  submitting: boolean
  loadAPI: Promise<any>
  stripe: any
  stripePk: string
  card: any
  payment: any
  address: any
  @Output() newCardAdded: EventEmitter<any> = new EventEmitter();
  cardReady = false;
  currentUser: User
  cardComplete: boolean = false
  addyComplete: boolean = false
  addyValue: any
  cardValue: any
  btnDisabled: boolean = true
  cardToken: string
  cardElement: any
  paymentIntentId: string
  cSecret: string
  isBrowser: boolean = false
  elements: any
  subscriptionId: string
  paymentIntentSecret: string
  cardError: string
  userLanguage: string = 'en'
  envUrl = environment.signupSuccessUrl
  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private stripeService: StripeService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private storageService: LocalStorageService,
    @Inject(PLATFORM_ID) platformId
  ) {
    this.currentUser = this.userService.currentUserValue
    this.stripePk = environment.stripePkey
    this.isBrowser = isPlatformBrowser(platformId)
    this.userLanguage = this.languageService.userLanguageValue
    if (this.userLanguage) {
      this.translateService.use(this.userLanguage)
    } else {
      const lang = this.translateService.getBrowserLang()
      if (lang) {
        this.translateService.use(lang)
        this.userLanguage = lang
      }
    }
  }

  ngOnInit() {
  }

  async loadStripe() {
    this.stripe = await loadStripe(this.stripePk);
    this.buildStripeElements()
  }


  ngAfterViewInit() {
    if (this.isBrowser) {
      this.loadStripe()
    }
  }

  buildStripeElements() {
    const stripe = this.stripe;
    const elOptions = {
      mode: 'subscription',
      amount: 499,
      currency: 'usd',
      locale: this.userLanguage
    }
    this.elements = stripe.elements(elOptions)
    const options = {
      layout: {
        type: 'tabs',
        defaultCollapsed: false,
      }
    }
    this.payment = this.elements.create('payment', options)
    this.payment.mount(this.paymentInfo.nativeElement)
    this.payment.addEventListener('change', this.cardHandler)
    this.address = this.elements.create('address', { mode: 'billing' })
    this.address.mount(this.addressInfo.nativeElement)
    this.address.addEventListener('change', this.addyHandler)
  }

  onCardChange(card: any) {
    if (card && card.complete) {
      this.cardElement = card
      this.cardValue = card.value
      this.cardComplete = true
      this.btnDisabled = false
      this.checkToSubmit()
    } else if (card.error) {
      this.btnDisabled = true
      this.cardElement = null
      this.cardComplete = false
      this.error = card.error.message
    } else {
      this.btnDisabled = true
      this.cardComplete = false
      this.error = null
    }
    this.cd.detectChanges();
  }

  onAddyChange(addy: any) {
    if (addy.complete) {
      this.addyComplete = true
      this.addyValue = addy.value
      this.checkToSubmit()
    } else if (addy.error) {
      this.addyComplete = false
      this.error = addy.error.message;
    } else {
      this.addyComplete = false
      this.error = null;
    }
    this.cd.detectChanges();
  }

  checkToSubmit() {
    if (this.addyComplete && this.cardComplete && !this.currentUser.customer_id) {
      this.createStripeCustomer()
    }
    if (this.cardComplete && this.addyComplete && this.currentUser.customer_id) {
      this.btnDisabled = false
    }
    else {
      this.btnDisabled = true
    }
  }

  createStripeCustomer() {
    const params = { email: this.currentUser.email }
    this.stripeService.createStripeCustomer(params).subscribe((res: any) => {
      if (res) {
        this.currentUser.customer_id = res.customer_id
        this.storageService.setItem('currentUser', this.currentUser)
        this.userService.setCurrentUser(this.currentUser)
        this.checkToSubmit()
      }
    }, error => {
      if (error) {
        this.error = error.message
      }
    })
  }

  createIncompleteSubscription() {
    let params = { email: this.currentUser.email, price_id: environment.stripePriceKey }
    if (this.currentUser.email) {
      if (this.currentUser.email.includes('@parentprotech.com')) {
        params = { email: this.currentUser.email, price_id: environment.stripeTestPriceKey }
      }
      if (this.currentUser.email.includes('@codefiworks.com')) {
        params = { email: this.currentUser.email, price_id: environment.stripeTestPriceKey }
      }
    }
    this.stripeService.createIncompleteSubscription(params).subscribe((res: any) => {
      if (res) {
        this.subscriptionId = res.id
        this.paymentIntentSecret = res.latest_invoice.payment_intent.client_secret
        this.confirmPayment()
      }
    })
  }

  async submitElements(event: any) {
    this.submitting = true
    event.preventDefault()
    const { res, error } = await this.elements.submit()
    if (error) {
      this.submitting = false
      this.error = error.message
    } else {
      this.submitting = false
      this.createIncompleteSubscription()
    }
  }



  async confirmPayment() {
    this.submitting = true
    const paymentElem = this.payment
    const params = {
      elements: this.elements,
      clientSecret: this.paymentIntentSecret,
      confirmParams: {
        return_url: this.envUrl,
      }
    }
    this.storageService.setItem('subscriptionId', this.subscriptionId)
    this.currentUser.subscription_id = this.subscriptionId
    this.storageService.setItem('currentUser', this.currentUser)
    this.userService.setCurrentUser(this.currentUser)
    const { error } = await this.stripe.confirmPayment(params);
    if (error) {
      this.submitting = false
      this.cardError = "Your Payment Failed"
      this.storageService.setItem('subscriptionId', null)
      this.storageService.removeItem('subscriptionId')
      this.currentUser.subscription_id = null
      this.currentUser.customer_id = null
      this.storageService.setItem('currentUser', this.currentUser)
      this.userService.setCurrentUser(this.currentUser)
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }


  onStateChange(event: any) {
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler)
      this.card.destroy()
    }
  }
}
