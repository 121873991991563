import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private userLanguageSubject: BehaviorSubject<string>
  public userLanguage: Observable<string>
  constructor(
    private router: Router,
    private http: HttpClient,
    private storage: LocalStorageService,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    const user = this.userService.currentUserValue
    if (user) {
      if (user.language) {
        if (user.language === 'english') {
          user.language = 'en'
          this.storage.setItem('currentLanguage', user.language)
        } else if (user.language === 'spanish') {
          user.language = 'es'
          this.storage.setItem('currentLanguage', user.language)
        }
        this.storage.setItem('currentLanguage', user.language)
      } else {
        this.storage.setItem('currentLanguage', 'en')
      }
      this.userLanguageSubject = new BehaviorSubject<string>(user.language)
    } else {
      let lang = this.storage.getItem('currentLanguage')
      if (!lang) {
        lang = 'en'
        this.storage.setItem('currentLanguage', lang)
      } else {
        if (lang === 'english') {
          lang = 'en'
          this.storage.setItem('currentLanguage', lang)
        } else if (lang === 'spanish') {
          lang = 'es'
          this.storage.setItem('currentLanguage', lang)
        } else {
          this.storage.setItem('currentLanguage', lang)
        }
      }
      this.userLanguageSubject = new BehaviorSubject<string>(lang)
    }

    this.userLanguage = this.userLanguageSubject.asObservable()
  }

  public get userLanguageValue(): string {
    return this.userLanguageSubject.value;
  }

  setUserLanguage(language: string) {
    const languageCode = this.getLanguageCode(language)
    this.translateService.use(languageCode)
    this.userLanguageSubject.next(languageCode)
  }

  setAndSaveUserLanguage(language: string) {
    const languageCode = this.getLanguageCode(language)
    this.translateService.use(languageCode)
    this.storage.setItem('currentLanguage', languageCode)
    this.userLanguageSubject.next(languageCode)
  }

  getLanguageCode(language: string) {
    let languageCode: string
    if (language === 'english' || language === 'en') {
      languageCode = 'en';
    } else if (language === 'spanish' || language === 'es') {
      languageCode = 'es'
    } else {
      languageCode = 'en'
    }
    return languageCode
  }


}
